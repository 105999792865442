import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Services
import { deleteUserAuth } from '@/services/storage/user-auth.service';

// graphql
import { useUshersSalesReportQuery, useDisabledUshersSalesReportQuery } from '@/generated/graphql';
import type { PageInfo } from '@/generated/graphql';

// material ui
import { Box } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// interfaces
import { mapGQLUsherSalesReport, mapGQLBet } from '@/shared/interfaces';
import type { IUsherReportQueryVariable, IUsherSalesReport, IBet } from '@/shared/interfaces';

// components
import { IDataFilter, DataFilter, UsherSalesTab, WinnerBetsTab } from './components';
import { DisabledUsherSalesTab } from './components/disabled-ushers-tab/index';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

export const Reports: React.FC = () => {
  const navigate = useNavigate();

  const { gameCtx } = useGameDashboardCtx();
  const [dataFilter, setDataFilter] = useState<IDataFilter>({
    dateFrom: '',
    dateTo: '',
  });
  const [tabValue, setTabValue] = useState('1');
  const [showLoading, setShowLoading] = useState(false);

  // DISABLED USHERS SALES REPORT
  const [disabledUshersSalesReport, setDisabledUshersSalesReport] = useState<IUsherSalesReport[]>([]);
  const [disabledWinnerBets, setDisabledWinnerBets] = useState<IBet[]>([]);
  const [disabledUSRpageInfo, setDisabledUSRPageInfo] = useState<PageInfo>();
  const [disabledUSRtotalCount, setDisabledUSRtotalCount] = useState<number>(0);
  const [disabledUSRQueryVariable, setDisabledUSRQueryVariable] = useState<IUsherReportQueryVariable>({
    first: 20,
  });
  const [disabledUsherResetData, setDisabledUsherResetData] = useState(false);

  // WINNER BETS STATES
  const [winnerBets, setWinnerBets] = useState<IBet[]>([]);

  // USHERS SALES REPORT STATES
  const [ushersSalesReport, setUshersSalesReport] = useState<IUsherSalesReport[]>([]);
  const [USRQueryVariable, setUSRQueryVariable] = useState<IUsherReportQueryVariable>({
    first: 100,
  });
  const [USRpageInfo, setUSRPageInfo] = useState<PageInfo>();
  const [USRtotalCount, setUSRtotalCount] = useState<number>(0);
  const [USRQuerySkipQuery, setUSRQuerySkipQuery] = useState(true);
  // other details
  const [resetData, setResetData] = useState(false);
  const [dateRangeLabel, setDateRangeLabel] = useState('');

  // QUERIES

  const usherSaleReportQuery = useUshersSalesReportQuery({
    variables: USRQueryVariable,
    fetchPolicy: 'cache-and-network',
    skip: USRQuerySkipQuery,
    onCompleted(data) {
      const mappedData: IUsherSalesReport[] = data.ushersSalesReport.nodes.map((x) => mapGQLUsherSalesReport(x));
      const ushershits = mappedData.filter((x) => x.winnerBets.length > 0).map((result) => result.winnerBets);
      let tempWinBets: IBet[] = [];
      if (ushershits) {
        ushershits.forEach((usherhits) => {
          tempWinBets = [...tempWinBets, ...usherhits];
        });
      }
      // show loading when fetching data
      if (!showLoading && ushersSalesReport.length < 25 && data.ushersSalesReport.totalCount >= 25) {
        setShowLoading(true);
      }

      if (ushersSalesReport.length === 0) {
        setDateRangeLabel(
          dataFilter.dateFrom === dataFilter.dateTo ? dataFilter.dateFrom : `${dataFilter.dateFrom} to ${dataFilter.dateTo}`,
        );
        setUshersSalesReport(mappedData);
        setWinnerBets(tempWinBets);
        setUSRPageInfo(data.ushersSalesReport.pageInfo);
        setUSRtotalCount(data.ushersSalesReport.totalCount);
      } else {
        setTimeout(() => {
          setDateRangeLabel(
            dataFilter.dateFrom === dataFilter.dateTo ? dataFilter.dateFrom : `${dataFilter.dateFrom} to ${dataFilter.dateTo}`,
          );
          setUshersSalesReport((ushersSalesReport) => [...ushersSalesReport, ...mappedData]);
          setWinnerBets((winnerBets) => [...winnerBets, ...tempWinBets]);
          setUSRPageInfo(data.ushersSalesReport.pageInfo);
        }, 230);
      }
    },
    onError: (error_resp) => {
      if (error_resp.message.includes('Unauthenticated')) {
        deleteUserAuth();
        navigate('/');
      }
    },
  });

  // DISABLED USHERS REPORT QUERY
  const disabledUsherSaleReportQuery = useDisabledUshersSalesReportQuery({
    variables: disabledUSRQueryVariable,
    fetchPolicy: 'cache-and-network',
    skip: USRQuerySkipQuery,
    onCompleted(data) {
      const mappedData: IUsherSalesReport[] = data.disabledUshersSalesReport.nodes.map((x) => mapGQLUsherSalesReport(x));
      const ushershits = mappedData.filter((x) => x.winnerBets.length > 0).map((result) => result.winnerBets);
      let tempWinBets: IBet[] = [];
      if (ushershits) {
        ushershits.forEach((usherhits) => {
          tempWinBets = [...tempWinBets, ...usherhits];
        });
      }
      // show loading when fetching data
      if (!showLoading && disabledUshersSalesReport.length < 25 && data.disabledUshersSalesReport.pageInfo.hasNextPage) {
        setShowLoading(true);
      }

      if (disabledUshersSalesReport.length === 0) {
        // setDateRangeLabel(
        //   dataFilter.dateFrom === dataFilter.dateTo ? dataFilter.dateFrom : `${dataFilter.dateFrom} to ${dataFilter.dateTo}`,
        // );
        setDisabledUshersSalesReport(mappedData);
        setDisabledWinnerBets(tempWinBets);
        setDisabledUSRPageInfo(data.disabledUshersSalesReport.pageInfo);
        setDisabledUSRtotalCount(data.disabledUshersSalesReport.totalCount);
      } else {
        setTimeout(() => {
          // setDateRangeLabel(
          //   dataFilter.dateFrom === dataFilter.dateTo ? dataFilter.dateFrom : `${dataFilter.dateFrom} to ${dataFilter.dateTo}`,
          // );
          setDisabledUshersSalesReport((disabledUshersSalesReport) => [...disabledUshersSalesReport, ...mappedData]);
          setDisabledWinnerBets((disabledWinnerBets) => [...disabledWinnerBets, ...tempWinBets]);
          setDisabledUSRPageInfo(data.disabledUshersSalesReport.pageInfo);
        }, 230);
      }
    },
  });

  // USE_EFFECT ------------------------------ this will watch USRpageInfo change to re-query more data if it detected data has next page ------------------------------
  useEffect(() => {
    if (USRpageInfo && USRpageInfo.hasNextPage && USRpageInfo.endCursor) {
      usherSaleReportQuery.fetchMore({
        variables: { ...USRQueryVariable, after: USRpageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
      });
    } else if (USRpageInfo && !USRpageInfo.hasNextPage) {
      setResetData(false);
      setShowLoading(false);
    }
    if (USRtotalCount >= 25 && ushersSalesReport.length > 25 && showLoading) {
      setShowLoading(false);
    }
  }, [USRpageInfo]);

  useEffect(() => {
    if (disabledUSRpageInfo && disabledUSRpageInfo.hasNextPage && disabledUSRpageInfo.endCursor) {
      disabledUsherSaleReportQuery.fetchMore({
        variables: { ...disabledUSRQueryVariable, after: disabledUSRpageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
      });
    } else if (disabledUSRpageInfo && !disabledUSRpageInfo.hasNextPage) {
      setDisabledUsherResetData(false);
      // setShowLoading(false);
    }
    // if (USRtotalCount >= 25 && ushersSalesReport.length > 25 && showLoading) {
    //   setShowLoading(false);
    // }
  }, [disabledUSRpageInfo]);

  // USE_EFFECT ------------------------------ Watching resetData state ------------------------------
  useEffect(() => {
    if (resetData) {
      setUshersSalesReport([]);
      setWinnerBets([]);
      setShowLoading(true);
    }
  }, [resetData]);
  // USE_EFFECT ------------------------------ Watching disabledUsherResetData state ------------------------------
  useEffect(() => {
    if (disabledUsherResetData) {
      setDisabledUshersSalesReport([]);
      // setShowLoading(true);
    }
  }, [disabledUsherResetData]);

  // USE_EFFECT ------------------------------ Watching gameCTX ------------------------------
  useEffect(() => {
    if (gameCtx.id.length > 0) {
      setResetData(true);
      setDisabledUsherResetData(true);
      setUSRQueryVariable({
        ...USRQueryVariable,
        filterBy: { ...USRQueryVariable.filterBy, gameId: gameCtx.id },
      });
      setDisabledUSRQueryVariable({
        ...disabledUSRQueryVariable,
        filterBy: { ...disabledUSRQueryVariable.filterBy, gameId: gameCtx.id },
      });
    } else if (gameCtx.id === '') {
      setResetData(true);
      setDisabledUsherResetData(true);
      setTimeout(() => {
        setUSRQueryVariable({
          ...USRQueryVariable,
          filterBy: { ...USRQueryVariable.filterBy, gameId: undefined },
        });
        setDisabledUSRQueryVariable({
          ...disabledUSRQueryVariable,
          filterBy: { ...disabledUSRQueryVariable.filterBy, gameId: undefined },
        });
      }, 50);
    }
  }, [gameCtx]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ background: 'white' }} py={0}>
        <DataFilter
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          setUSRQuerySkipQuery={setUSRQuerySkipQuery}
          USRQueryVariable={USRQueryVariable}
          setUSRQueryVariable={setUSRQueryVariable}
          disabledUSRQueryVariable={disabledUSRQueryVariable}
          setDisabledUSRQueryVariable={setDisabledUSRQueryVariable}
          setResetData={setResetData}
          setDisabledUsherResetData={setDisabledUsherResetData}></DataFilter>
      </Box>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Sales" value="1" />
              <Tab label="Hits" value="2" />
              <Tab label="Disabled Ushers" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <UsherSalesTab
              ushersSalesReport={ushersSalesReport}
              winnerBets={winnerBets}
              USRpageInfo={USRpageInfo}
              USRtotalCount={USRtotalCount}
              resetData={resetData}
              dateRangeLabel={dateRangeLabel}></UsherSalesTab>
          </TabPanel>
          <TabPanel value="2">
            <WinnerBetsTab
              winnerBets={winnerBets}
              USRpageInfo={USRpageInfo}
              USRtotalCount={USRtotalCount}
              resetData={resetData}
              dateRangeLabel={dateRangeLabel}></WinnerBetsTab>
          </TabPanel>
          <TabPanel value="3">
            <DisabledUsherSalesTab
              ushersSalesReport={disabledUshersSalesReport}
              winnerBets={disabledWinnerBets}
              USRpageInfo={disabledUSRpageInfo}
              USRtotalCount={disabledUSRtotalCount}
              resetData={disabledUsherResetData}
              dateRangeLabel={dateRangeLabel}></DisabledUsherSalesTab>
          </TabPanel>
        </TabContext>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
