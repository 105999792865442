import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { getUserAuth, deleteUserAuth } from '@/services/storage/user-auth.service';
import { GraphQLErrorCode } from '@/shared/constants';

export function createApolloClient() {
  // HTTP connection to the API
  const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: `${
      process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL
    }/graphql`,
  });

  const authLink = new ApolloLink((operation, forward) => {
    const userData = getUserAuth();
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: userData ? `Bearer ${userData.token}` : '',
      },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log('🚀 ~ errorLink ~ err:', err);
        return forward(operation);
      }
    }

    // To retry on network errors, we recommend the RetryLink

    // instead of the onError link. This just logs the error.

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  // Cache implementation
  const cache = new InMemoryCache();

  const link = ApolloLink.from([authLink, httpLink, errorLink]);

  // Create the apollo client
  return new ApolloClient({
    link,
    cache,
  });
}

// // HTTP connection to the API
// const httpLink = createHttpLink({
//   // You should use an absolute URL here
//   uri: `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL}/graphql`,
// });

// const userData = getUserAuth();

// const authLink = new ApolloLink((operation, forward) => {
//   // Use the setContext method to set the HTTP headers.
//   operation.setContext({
//     headers: {
//       authorization: userData ? `Bearer ${userData.token}` : '',
//     },
//   });

//   // Call the next link in the middleware chain.
//   return forward(operation);
// });

// const logoutLink = onError(({ graphQLErrors }) => {
//   if (!graphQLErrors) {
//     return;
//   }

//   const authError = graphQLErrors.find(
//     x => x.extensions?.code === GraphQLErrorCode.UNAUTHENTICATED,
//   );

//   if (authError) {
//     localStorage.removeItem(LocalStorageKey.USER);
//   }
// });

// // Cache implementation
// const cache = new InMemoryCache();

// const link = ApolloLink.from([authLink, logoutLink, httpLink]);

// // Create the apollo client
// export const apolloClient = new ApolloClient({
//   link,
//   cache,
// });
