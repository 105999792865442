// material ui
import { Box, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//Services
import { deleteUserAuth } from '@/services/storage/user-auth.service';

import { useContext, useState, useEffect } from 'react';

import './game-results.css';

// interfaces
import { useGameDashboardCtx } from '@/layouts/components/sidebar';
import type { PageInfo } from '@/generated/graphql';
import { useGameResultsQuery, useNoGameResultsQuery, usePageGameResultGameDrawTimesQuery } from '@/generated/graphql';
import type { IGameResult, IGameResultQueryVariable, IGameResultTableData, IGameDrawTime } from '@/shared/interfaces';
import { mapGQLGameResult, mapGQLGameDrawTime } from '@/shared/interfaces';

// graphql

// context api

// component
import { GameResultsTable, AddGameResultModal } from './components';

export const GameResults: React.FC = () => {
  const navigate = useNavigate();

  const today = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Manila',
    }),
  );

  const { gameCtx } = useGameDashboardCtx();

  // game results
  const [gameResults, setGameResults] = useState<IGameResultTableData[]>([]);
  const [gameResultQueryVariable, setGameResultQueryVariable] = useState<IGameResultQueryVariable>({
    first: 10,
    filterBy: {
      date: `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`,
    },
  });

  // no game result
  const [noGameResults, setNoGameResults] = useState<IGameResult[]>([]);
  const [noGameResult, setNoGameResult] = useState<IGameResult>();
  const [noGameResultQueryVariable, setNoGameResultQueryVariable] = useState<IGameResultQueryVariable>({ first: 1000 });

  const [gameDrawTimes, setGameDrawTimes] = useState<IGameDrawTime[]>([]);

  // pagination
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  // GRAPHQL QUERIES
  const getGameResults = useGameResultsQuery({
    variables: gameResultQueryVariable,
    skip: gameCtx.id.length > 0 ? false : true,
    onCompleted: (data) => {
      const mappedGameResult: IGameResultTableData[] = [];
      data.gameResults.nodes.map((result) => {
        const mapResult: IGameResult = mapGQLGameResult(result);
        mappedGameResult.push({
          id: mapResult.id,
          resultCombination: mapResult.resultCombination.replace(/,/g, ''),
          originalResultCombination: mapResult.resultCombination,
          draw: mapResult.gameDrawTime.drawTime.name,
          drawDate: mapResult.date,
          gameDrawTimeId: mapResult.gameDrawTime.id,
        });
      });
      setTimeout(() => {
        setGameResults(mappedGameResult);
        setPageInfo(data.gameResults.pageInfo);
        setTotalCount(data.gameResults.totalCount);
      }, 100);
    },
    onError: (error_resp) => {
      if (error_resp.message.includes('Unauthenticated')) {
        deleteUserAuth();
        navigate('/');
      }
    },
  });

  const getNoGameResults = useNoGameResultsQuery({
    variables: noGameResultQueryVariable,
    skip: gameCtx.id.length > 0 ? false : true,
    onCompleted: (data) => {
      const mappedNoGameResult: IGameResult[] = [];
      data.noGameResults.nodes.map((result) => {
        const mapResult: IGameResult = mapGQLGameResult(result);
        mappedNoGameResult.push(mapResult);
      });
      setTimeout(() => {
        setNoGameResults(mappedNoGameResult);
      }, 100);
    },
  });

  const getGameDrawTimes = usePageGameResultGameDrawTimesQuery({
    skip: gameCtx.id.length > 0 ? false : true,
    variables: { filterBy: { gameId: gameCtx.id } },
    onCompleted: (data) => {
      const mappedDatas: IGameDrawTime[] = [];

      data.gameDrawTimes.nodes.forEach((gameDrawTime) => {
        mappedDatas.push(mapGQLGameDrawTime(gameDrawTime));
      });

      setTimeout(() => {
        setGameDrawTimes(mappedDatas);
      }, 100);
    },
  });

  // END GRAPHQL QUERIES

  useEffect(() => {
    if (gameCtx.id.length > 1) {
      // get Date today
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      const todayDate = yyyy + '-' + mm + '-' + dd;

      setGameResultQueryVariable({ ...gameResultQueryVariable, filterBy: { gameId: gameCtx.id, date: todayDate } });
      setNoGameResultQueryVariable({ ...noGameResultQueryVariable, filterBy: { gameId: gameCtx.id } });
    }
  }, [gameCtx]);

  const refetchData = () => {
    const { refetch: refetchGameResults } = getGameResults;
    const { refetch: refetchNoGameResults } = getNoGameResults;
    const { refetch: refetchGameDrawTimes } = getGameDrawTimes;

    refetchGameResults().then((res) => {
      if (!res.loading && !res.error && res.data?.gameResults) {
        const mappedGameResult: IGameResultTableData[] = [];
        res.data.gameResults.nodes.map((result) => {
          const mapResult: IGameResult = mapGQLGameResult(result);
          mappedGameResult.push({
            id: mapResult.id,
            resultCombination: mapResult.resultCombination.replace(/,/g, ''),
            originalResultCombination: mapResult.resultCombination,
            draw: mapResult.gameDrawTime.drawTime.name,
            drawDate: mapResult.date,
            gameDrawTimeId: mapResult.gameDrawTime.id,
          });
        });
        setGameResults(mappedGameResult);
        setPageInfo(res.data.gameResults.pageInfo);
        setTotalCount(res.data.gameResults.totalCount);
      }
    });

    refetchNoGameResults().then((res) => {
      if (!res.loading && !res.error && res.data?.noGameResults) {
        const mappedNoGameResult: IGameResult[] = [];
        res.data.noGameResults.nodes.map((result) => {
          const mapResult: IGameResult = mapGQLGameResult(result);
          mappedNoGameResult.push(mapResult);
        });
        setNoGameResults(mappedNoGameResult);
        setNoGameResult(mappedNoGameResult[0]);
      }
    });

    refetchGameDrawTimes().then((res) => {
      if (!res.loading && !res.error && res.data?.gameDrawTimes) {
        const mappedDatas: IGameDrawTime[] = [];

        res.data.gameDrawTimes.nodes.forEach((gameDrawTime) => {
          mappedDatas.push(mapGQLGameDrawTime(gameDrawTime));
        });

        setGameDrawTimes(mappedDatas);
      }
    });
  };

  const filterGameresultByDate = (value: string) => {
    if (value.length > 0) {
      setGameResultQueryVariable({ ...gameResultQueryVariable, filterBy: { gameId: gameCtx.id, date: value } });
    } else {
      setGameResultQueryVariable({ ...gameResultQueryVariable, filterBy: { gameId: gameCtx.id } });
    }
  };

  return (
    <>
      <Box height={80} mt={3} mb={0} sx={{ background: '#87ADD4' }} alignItems={'center'} display={'flex'}>
        <Grid container>
          <Grid item md={10}>
            <TextField
              onChange={(e) => filterGameresultByDate(e.target.value)}
              id="date"
              label="Filter by date"
              type="date"
              size="small"
              value={gameResultQueryVariable.filterBy?.date}
              sx={{ width: 220, ml: 3 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={2} justifyContent={'end'} display={'flex'}>
            <AddGameResultModal
              noGameResults={noGameResults}
              noGameResult={noGameResult}
              setNoGameResult={setNoGameResult}
              refetchData={refetchData}
              gameDrawTimes={gameDrawTimes}
            />
          </Grid>
        </Grid>
      </Box>
      <GameResultsTable
        rows={gameResults}
        gameResultQueryVariable={gameResultQueryVariable}
        setGameResultQueryVariable={setGameResultQueryVariable}
        totalCount={totalCount}
        pageInfo={pageInfo}
        refetchData={refetchData}
      />
    </>
  );
};
