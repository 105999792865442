import { ApolloProvider } from '@apollo/client';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';

import './assets/styles/index.css';
import './assets/styles/layouts.css';

import { getUserAuth } from '@/services/storage/user-auth.service';
// graphql
import { createApolloClient } from './graphql-apollo';
// routes
import { Routes } from './routes';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    if (config.headers) {
      const userAuth = getUserAuth();

      config.headers['authorization'] = getUserAuth() ? `Bearer ${userAuth ? userAuth.token : ''}` : '';
      return config;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

function App() {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const [apolloClient, setApolloClient] = useState(createApolloClient);

  useEffect(() => {
    if (authenticated) {
      setApolloClient(createApolloClient);
    }
  }, [authenticated]);

  return (
    <ApolloProvider client={apolloClient}>
      <CookiesProvider>
        <Routes setAuthenticated={setAuthenticated} />
      </CookiesProvider>
    </ApolloProvider>
  );
}

export default App;
