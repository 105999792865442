import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Services
import { deleteUserAuth } from '@/services/storage/user-auth.service';

//Material UI
import { Box, Tab, Typography } from '@mui/material';

/* GRAPHQL */
import { useGameDashboardCtx } from '@/layouts/components/sidebar';
import { PageInfo, useOpStatsNumberCombinationSalesQuery, useOpStatsGameDrawTimesQuery } from '@/generated/graphql';
import { useOpStatsNumberCombinationsSoldOutQuery, NumberCombinationSalesSortField, SortDirection } from '@/generated/graphql';

/* Time Related Imports */
import { defaultDateFilter } from './service/time';

/* Components */
import { NumberCombinationTable, Filter, LowestNumberCombinationTable } from './components';

/* Interfaces */
import {
  IOpstatsTableData,
  INumberCombinationSalesQueryVariable,
  INumberCombinationSales,
  mapGQLNumberCombinationSales,
} from '@/shared/interfaces';
import {
  INumberCombinationSoldOutTableData,
  MapGQLNumberCombinationSoldOutTableData,
  INumberCombinationSoldOutQueryVariable,
} from '@/shared/interfaces';

//Stylesheets
import './styles/opstats.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export function OpStats() {
  const navigate = useNavigate();

  const { gameCtx } = useGameDashboardCtx();

  // pagination
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lowestNumberPageInfo, setLowestNumberPageInfo] = useState<PageInfo>();
  const [lowestNumberTotalCount, setLowestNumberTotalCount] = useState<number>(0);

  //STATES
  const [numberCombinationSoldOut, setNumberCombinationSoldOut] = useState<INumberCombinationSoldOutTableData[]>([]);
  const [opStatsTableDatas, setOpStatsTableDatas] = useState<IOpstatsTableData[]>([]);
  const [value, setValue] = useState('1');

  const [lowestNumbers, setLowestNumbers] = useState<IOpstatsTableData[]>([]);

  //query variables

  const [opStatsNumberSalesQueryVariable, setopStatsNumberSalesQueryVariable] = useState<INumberCombinationSalesQueryVariable>({
    filterBy: {
      date: defaultDateFilter.dateFrom,
      gameDrawTimeId: '',
    },
  });

  const [lowestNumberQueryVariable, setLowestNumberQueryVariable] = useState<INumberCombinationSalesQueryVariable>({
    filterBy: {
      date: defaultDateFilter.dateFrom,
      gameDrawTimeId: '',
    },
  });

  const [opStatsSoldOutQueryVariable, setOpStatsSoldOutQueryVariable] = useState<INumberCombinationSoldOutQueryVariable>({
    first: 25,
    filterBy: {
      date: defaultDateFilter.dateFrom,
    },
  });

  //Query
  useOpStatsNumberCombinationsSoldOutQuery({
    skip: opStatsSoldOutQueryVariable.filterBy?.gameId && opStatsSoldOutQueryVariable.filterBy?.gameDrawTimeId ? false : true,
    variables: opStatsSoldOutQueryVariable,
    onCompleted: (soldOutData) => {
      const mappedSoldOutData: INumberCombinationSoldOutTableData[] = soldOutData.numberCombinationSoldOut.nodes.map((numberSoldOut) =>
        MapGQLNumberCombinationSoldOutTableData(numberSoldOut),
      );
      setTimeout(() => {
        setNumberCombinationSoldOut(mappedSoldOutData);
      }, 100);
    },
  });
  useOpStatsNumberCombinationSalesQuery({
    variables: opStatsNumberSalesQueryVariable,
    skip: opStatsNumberSalesQueryVariable.filterBy?.gameId && opStatsNumberSalesQueryVariable.filterBy?.gameDrawTimeId ? false : true,
    onCompleted: (data) => {
      console.log('🚀 ~ file: opstats.page.tsx:92 ~ OpStats ~ data:', data);
      const mappedOpstats: IOpstatsTableData[] = [];
      data.numberCombinationSales.map((result) => {
        const mapResult: INumberCombinationSales = mapGQLNumberCombinationSales(result);

        mappedOpstats.push({
          id: mapResult.id,
          numberCombination: mapResult.numberCombination.replace(/,/g, ''),
          numberCombinationOriginal: mapResult.numberCombination,
          sales: mapResult.sales,
          numberOfBets: mapResult.numberOfBets,
        });
      });
      setTimeout(() => {
        console.log('HIGH', mappedOpstats);
        setOpStatsTableDatas(mappedOpstats);
      }, 100);
    },
    onError: (error_resp) => {
      if (error_resp.message.includes('Unauthenticated')) {
        deleteUserAuth();
        navigate('/');
      }
    },
  });
  // lowest number query
  useOpStatsNumberCombinationSalesQuery({
    variables: lowestNumberQueryVariable,
    skip: lowestNumberQueryVariable.filterBy?.gameId && lowestNumberQueryVariable.filterBy?.gameDrawTimeId ? false : true,
    onCompleted: (data) => {
      const mappedOpstats: IOpstatsTableData[] = [];
      data.numberCombinationSales.map((result) => {
        const mapResult: INumberCombinationSales = mapGQLNumberCombinationSales(result);

        mappedOpstats.push({
          id: mapResult.id,
          numberCombination: mapResult.numberCombination.replace(/,/g, ''),
          numberCombinationOriginal: mapResult.numberCombination,
          sales: mapResult.sales,
          numberOfBets: mapResult.numberOfBets,
        });
      });
      setTimeout(() => {
        console.log('LOWEST', mappedOpstats);
        setLowestNumbers(mappedOpstats);
      }, 100);
    },
  });

  useEffect(() => {
    if (gameCtx.id.length > 1) {
      setopStatsNumberSalesQueryVariable({
        ...opStatsNumberSalesQueryVariable,
        filterBy: { ...opStatsNumberSalesQueryVariable.filterBy, gameId: gameCtx.id },
      });
      setLowestNumberQueryVariable({
        ...lowestNumberQueryVariable,
        filterBy: { ...lowestNumberQueryVariable.filterBy, gameId: gameCtx.id },
      });
      setOpStatsSoldOutQueryVariable({
        ...opStatsSoldOutQueryVariable,
        filterBy: { ...opStatsSoldOutQueryVariable.filterBy, gameId: gameCtx.id },
      });
    }
  }, [gameCtx]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="opstats-page-container">
        <div className="opstats-page-header">
          <Typography variant="h5" style={{ fontWeight: '600', fontSize: '1.3em', marginBottom: '0.5em ' }}>
            Operation Stats
          </Typography>
          <Filter
            opStatsNumberSalesQueryVariable={opStatsNumberSalesQueryVariable}
            setopStatsNumberSalesQueryVariable={setopStatsNumberSalesQueryVariable}
            opStatsSoldOutQueryVariable={opStatsSoldOutQueryVariable}
            setOpStatsSoldOutQueryVariable={setOpStatsSoldOutQueryVariable}
            lowestNumberQueryVariable={lowestNumberQueryVariable}
            setLowestNumberQueryVariable={setLowestNumberQueryVariable}></Filter>
        </div>
        <div className="opstats-tables-container" style={{ display: 'grid', gridTemplateColumns: '2.5fr 1fr' }}>
          <div className="number-combination-table-container">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                    <Tab label="Top Numbers" value="1" />
                    <Tab label="Lowest 20 Numbers" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <NumberCombinationTable
                    rows={opStatsTableDatas}
                    opStatsQueryVariable={opStatsNumberSalesQueryVariable}
                    setOpStatsQueryVariable={setopStatsNumberSalesQueryVariable}
                    totalCount={totalCount}
                    pageInfo={pageInfo}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <LowestNumberCombinationTable
                    rows={lowestNumbers}
                    opStatsQueryVariable={lowestNumberQueryVariable}
                    setOpStatsQueryVariable={setLowestNumberQueryVariable}
                    totalCount={lowestNumberTotalCount}
                    pageInfo={lowestNumberPageInfo}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
          <div className="sold-out-table" style={{ backgroundColor: '#dedede' }}>
            <div className="sold-out-header">SOLD OUT</div>
            {numberCombinationSoldOut.map((soldOutNumber) => (
              <div className="sold-out-cell" style={{ padding: '0.5em 1em', color: '#555', backgroundColor: '#fff' }}>
                {soldOutNumber.numberCombination.replace(/,/g, '')}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
