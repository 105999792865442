import type { TDocumentDefinitions } from 'pdfmake/interfaces';

import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import { HeadCell } from '../components/month-daily-collections-table';
import { ICoordinator, ICoordinatorDailyCollection } from '@/shared/interfaces';

pdfMake.vfs = pdfFonts.vfs;

export function coordinatorDailyCollectionExportToPDF(
  datas: ICoordinatorDailyCollection[],
  headCells: HeadCell[],
  coordinator: ICoordinator | undefined,
  title: string,
  forwardBalance: number,
) {
  let totalSales = 0;
  let totalWinAmount = 0;
  let totalCommission = 0;
  let totalPayment = 0;
  const tableData: any = [];
  const headCellsFinal = headCells.filter((headCell) => headCell.hide === false);

  tableData.push(headCellsFinal.map((headCell) => ({ text: headCell.label, style: 'tableHeader', alignment: headCell.tbCellAlign })));

  tableData.push(
    ['Forwarded Balance', '', '', '', '', '', forwardBalance].map((el) => {
      return {
        text: el,
        alignment: 'center',
        fontSize: 8,
      };
    }),
  );

  datas.forEach((x) => {
    totalSales += x.grossSales;
    totalWinAmount += x.hitsAmount;
    totalCommission += x.commissionAmount;
    totalPayment += x.payment;

    tableData.push(
      headCellsFinal.map((headCell) => {
        return {
          text: x[headCell.id],
          alignment: headCell.tbCellAlign,
          fontSize: 8,
          color: headCell.numeric && x[headCell.id] < 0 ? 'red' : 'black',
        };
      }),
    );
  });

  const documentDefinition: TDocumentDefinitions = {
    pageSize: 'LETTER',
    pageOrientation: 'landscape',
    pageMargins: [50, 20, 50, 20],
    footer: function (currentPage, pageCount) {
      return { text: `Page ${currentPage}`, fontSize: 8, alignment: 'right', margin: [0, 25, 15, 0] };
    },
    content: [
      {
        text: [
          {
            text: `\n${title}\n\n`,
            alignment: 'center',
            fontSize: 10,
            bold: true,
          },
        ],
      },
      {
        alignment: 'justify',
        columns: [
          {
            columns: [
              { text: 'Name of Operator:', bold: true, fontSize: 8 },
              { text: `  ${coordinator?.name.toUpperCase()}              `, bold: true, fontSize: 10, decoration: 'underline' },
            ],
          },
          {
            text: '',
          },
        ],
      },
      {
        alignment: 'justify',
        columns: [
          {
            columns: [
              { text: 'Area:', bold: true, fontSize: 8 },
              { text: `${coordinator?.area.toUpperCase()}`, bold: true, fontSize: 9 },
            ],
          },
          {
            text: '',
          },
        ],
      },
      {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: ['auto', '*', '*', '*', '*', '*', '*'],
          body: tableData,
        },
        layout: 'lightHorizontalLines',
      },
      {
        style: 'tableExample1',
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [false, false, false, false],
                stack: [
                  {
                    alignment: 'justify',
                    columns: [
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL GROSS: ${totalSales.toFixed(2)}`],
                      },
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL COMM.: ${totalCommission.toFixed(2)}`],
                      },
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL PAYOUT: ${totalWinAmount.toFixed(2)}`],
                      },
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL PAYMENT: ${totalPayment.toFixed(2)}`],
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
    ],
    styles: {
      tableExample: {
        margin: [0, 2, 0, 7],
      },
      tableExample1: {
        margin: [0, 8, 0, 15],
        fontSize: 10,
        bold: true,
        fillColor: '#dfdfdf',
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: '#000000',
        fillColor: '#BBDEFB',
      },
      tableHeader1: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'right',
      },
      tableHeader2: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'left',
      },
      tableHeader3: {
        bold: true,
        fontSize: 9,
        color: '#343434',
        margin: [0, 0, 0, 1000],
      },
      tableHeader4: {
        bold: true,
        fontSize: 9,
        alignment: 'right',
        color: '#343434',
      },
    },
  };
  pdfMake.createPdf(documentDefinition).print();
}
