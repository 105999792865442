import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

//Services
import { deleteUserAuth } from '@/services/storage/user-auth.service';

// material ui
import { Button, ClickAwayListener, Grow, ListItemIcon, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { TbLogout } from 'react-icons/tb';
import { FaUserCircle, FaUserEdit } from 'react-icons/fa';
import { AiFillLock } from 'react-icons/ai';

// interfaces
import type { ICurrentUserLogin } from '@/shared/interfaces';
import { mapGQLUser } from '@/shared/interfaces';

// graphql
import { useLogoutMutation, useCurrentUserQuery } from '@/generated/graphql';

// components
import { EditProfileModal } from './edit-profile';
import { UpdatePasswordModal } from './update-password';

export const TopNavbar: React.FC = () => {
  const navigate = useNavigate();

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [profileDropDown, setProfileDropDown] = useState(false);

  const [isUpdateAdminModalOpen, setIsUpdateAdminModalOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const [logoutMutation] = useLogoutMutation();

  const [currentUserLogin, setCurrentUserLogin] = useState<ICurrentUserLogin | null>(null);

  const currentUserQuery = useCurrentUserQuery({
    onCompleted: (data) => {
      const user = mapGQLUser(data.me);

      setCurrentUserLogin({
        id: user.id,
        username: user.username,
        role: user.role.name,
      });
    },
    onError: (error_resp) => {
      if (error_resp.message.includes('Unauthenticated')) {
        deleteUserAuth();
        navigate('/');
      }
    },
  });

  const logout = async () => {
    logoutMutation({
      onCompleted(data) {
        console.log('logout');
      },
    });

    setTimeout(() => {
      deleteUserAuth();
      navigate('/');
    }, 300);
  };

  const refetch = () => {
    currentUserQuery.refetch();
  };

  return (
    <>
      <div className="top-navbar">
        <div />
        <div className="nav-user ">
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={profileDropDown ? 'composition-menu' : undefined}
            aria-expanded={profileDropDown ? 'true' : undefined}
            aria-haspopup="true"
            onClick={() => setProfileDropDown((prevValue) => !prevValue)}
            style={{
              display: 'inline-flex',
              color: '#FFF',
              width: '100%',
              height: '100%',
              fontSize: '0.8em',
              gap: '1.5em',
            }}>
            <FaUserCircle color="DFDFDF" size="3em" />
            <div className="user-details">
              <p className="header-name">{currentUserLogin?.username}</p>
              <p className="header-role">{currentUserLogin?.role} </p>
            </div>
            {/* </div> */}
          </Button>
          <Popper
            style={{ zIndex: '2', width: 'calc(100vw/7)' }}
            open={profileDropDown}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setProfileDropDown(false)}>
                    <MenuList autoFocusItem={profileDropDown} id="composition-menu" aria-labelledby="composition-button">
                      <MenuItem onClick={logout}>
                        <ListItemIcon>
                          <TbLogout color="#849db9" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                      <MenuItem onClick={() => setIsEditProfileModalOpen(true)}>
                        <ListItemIcon>
                          <FaUserEdit color="#849db9" />
                        </ListItemIcon>
                        Edit Profile
                      </MenuItem>
                      <MenuItem onClick={() => setIsUpdateAdminModalOpen(true)}>
                        <ListItemIcon>
                          <AiFillLock color="#849db9" />
                        </ListItemIcon>
                        Update Password
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <EditProfileModal
        isEditProfileModalOpen={isEditProfileModalOpen}
        setIsEditProfileModalOpen={setIsEditProfileModalOpen}
        refetchData={refetch}
        currentUserLogin={currentUserLogin}
      />
      <UpdatePasswordModal
        isUpdatePasswordModalOpen={isUpdateAdminModalOpen}
        setIsUpdatePasswordModalOpen={setIsUpdateAdminModalOpen}
        refetchData={refetch}
        currentUserLogin={currentUserLogin}
      />
    </>
  );
};
