import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

//Services
import { deleteUserAuth } from '@/services/storage/user-auth.service';

//GraphQL
import { useGamesOverviewReportQuery } from '@/generated/graphql';

//interfaces
import type { IGame, IGamesOverviewReportQueryVariable, IGamesOverviewReport } from '@/shared/interfaces';
import { mapGQLGame, mapGQLGamesOverviewReport } from '@/shared/interfaces';

// components
import { OverviewSummaryHeader, OverviewGamesReport, OverviewGameReport, OverviewGameDrawtimesReport } from './components';

import './styles/gdt.css';
import './styles/overview-mq.css';

import { GameTypeDashboardLayout } from '@/components/gametype-carousel/gametype-carousel';

export interface IDataFilter {
  date: string;
}

export function NewOverview() {
  const navigate = useNavigate();

  const [gameCtx, setGameCtx] = useState<IGame>(mapGQLGame());

  //State And Context
  const [gamesReportQueryVariable, setGamesReportQueryVariable] = useState<IGamesOverviewReportQueryVariable>({
    filterBy: {
      gameId: null,
    },
  });

  const [gamesReportData, setGamesReportData] = useState<IGamesOverviewReport>();

  //QUERIES

  const test = useGamesOverviewReportQuery({
    variables: gamesReportQueryVariable,
    skip: gamesReportQueryVariable.filterBy.dateFrom && gamesReportQueryVariable.filterBy.gameId ? false : true,
    onCompleted(data) {
      const mappedData = mapGQLGamesOverviewReport(data.gamesOverviewReport);
      setTimeout(() => {
        setGamesReportData(mappedData);
      }, 100);
    },
    onError: (error_resp) => {
      console.log('🚀 ~ NewOverview ~ error_resp:', error_resp);
      if (error_resp.message.includes('Unauthenticated')) {
        deleteUserAuth();
        navigate('/');
      }
    },
  });

  useEffect(() => {
    if (gameCtx.id.length > 0) {
      setGamesReportQueryVariable({
        ...gamesReportQueryVariable,
        filterBy: {
          ...gamesReportQueryVariable.filterBy,
          gameId: gameCtx.id,
        },
      });
    }
  }, [gameCtx]);

  return (
    <>
      <div className="overview-summary">
        <OverviewSummaryHeader
          gamesReportQueryVariable={gamesReportQueryVariable}
          setGamesReportQueryVariable={setGamesReportQueryVariable}
        />
        <OverviewGamesReport gamesReportData={gamesReportData} />
      </div>
      <GameTypeDashboardLayout gameCtx={gameCtx} setGameCtx={setGameCtx} />
      <div className="gametype-overview" style={{ padding: '1em' }}>
        <Typography variant="h4" sx={{ fontFamily: 'Inter', color: '#444', fontWeight: '600' }}>
          {gamesReportData?.gameReport.game.gameType.name}
        </Typography>
        <div className="gametypedata">
          <OverviewGameReport gameReport={gamesReportData?.gameReport} />
        </div>
        <hr />
        <div className="resultscontainer">
          <OverviewGameDrawtimesReport
            gameDrawTimesReport={
              gamesReportData ? orderBy(gamesReportData.gameReport.gameDrawTimesReport, ['drawTime.endTime'], ['asc']) : []
            }
          />
        </div>
      </div>
    </>
  );
}
